import React from 'react';
import { PropsWithClassName } from '@Components/helper';
import { TextColor } from '@Helpers/types/text';
import { IconBackground } from './IconButton.styles';
interface Props extends PropsWithClassName {
  children: React.ReactNode;
  active?: boolean;
  onClick?: () => void;
  color?: TextColor;
  size?: 'small' | 'medium' | 'large';
}
const IconButton = (props: Props): React.ReactElement => {
  const {
    children,
    onClick,
    active,
    color = TextColor.Primary,
    size = 'small'
  } = props;
  const handleClick = (): void => {
    if (onClick) {
      onClick();
    }
  };
  return <IconBackground className={props.className} $active={active} $color={color} $size={size} data-testid={props.dataTestId ?? 'IconButton-root'} onClick={handleClick} data-sentry-element="IconBackground" data-sentry-component="IconButton" data-sentry-source-file="IconButton.tsx">
      {children}
    </IconBackground>;
};
export { IconButton };