'use client';

import { IconShare } from '@tabler/icons-react';
import React from 'react';
import UserAchievementModel from '@Api/models/UserAchievementModel';
import Text from '@Components/atoms/Text';
import { PropsWithClassName } from '@Components/helper';
import { getAchievementIcon } from '@Components/helper/achievement';
import { getDynamicSubRoute } from '@Components/helper/url';
import { useBreakpoint } from '@Components/helper/useBreakpoint';
import RewardBadge from '@Components/molecules/RewardBadge/RewardBadge';
import { Routes } from '@Components/Routes';
import useRouter from '@Helpers/i18n/useRouter';
import { ShareMode } from '@Helpers/types/share';
import { TextSize } from '@Helpers/types/text';
import { Root, StyledIconButton } from './RewardNotification.styles';
interface Props extends PropsWithClassName {
  message: string;
  achievement: UserAchievementModel;
}
const RewardNotification = (props: Props): React.ReactElement => {
  const {
    message,
    achievement
  } = props;
  const router = useRouter();
  const {
    isMobile
  } = useBreakpoint();
  return <Root className={props.className} data-testid={props.dataTestId ?? 'reward-notification-root'} data-sentry-element="Root" data-sentry-component="RewardNotification" data-sentry-source-file="RewardNotification.tsx">
      <RewardBadge achievementType={achievement.achievementType} icon={getAchievementIcon(achievement.achievementType)} size={isMobile ? 'small' : 'default'} data-sentry-element="RewardBadge" data-sentry-source-file="RewardNotification.tsx" />
      <Text size={TextSize.Medium} mobileSize={TextSize.Small} data-sentry-element="Text" data-sentry-source-file="RewardNotification.tsx">
        {message}
      </Text>
      <StyledIconButton onClick={() => router.push(getDynamicSubRoute(Routes.Share, ShareMode.Achievement, achievement.id, 'image'))} data-sentry-element="StyledIconButton" data-sentry-source-file="RewardNotification.tsx">
        <IconShare data-sentry-element="IconShare" data-sentry-source-file="RewardNotification.tsx" />
      </StyledIconButton>
    </Root>;
};
export default RewardNotification;