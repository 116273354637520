import React from 'react';
import { PropsWithClassName } from '@Components/helper';
import { Progress, Root } from './ProgressBar.styles';
interface Props extends PropsWithClassName {
  progress: number;
}
const ProgressBar = (props: Props): React.ReactElement => {
  const {
    progress
  } = props;
  return <Root className={props.className} data-testid={'ProgressBar-root'} data-sentry-element="Root" data-sentry-component="ProgressBar" data-sentry-source-file="ProgressBar.tsx">
      <Progress width={progress} data-sentry-element="Progress" data-sentry-source-file="ProgressBar.tsx" />
    </Root>;
};
export { ProgressBar };