import { usePathname } from 'next/navigation';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AchievementType } from '@Api/models/UserAchievementModel';
import DateTimeDistance from '@Components/atoms/DateTime/DateTimeDistance';
import Row from '@Components/atoms/Row';
import Skeleton from '@Components/atoms/Skeleton';
import Text from '@Components/atoms/Text';
import { PropsWithClassName } from '@Components/helper';
import { getAchievementIcon } from '@Components/helper/achievement';
import { getStringWithLength } from '@Components/helper/utils';
import useRouter from '@Helpers/i18n/useRouter';
import { TextColor, TextSize, TextTransform } from '@Helpers/types/text';
import { Content, Level, LevelContainer, PointsWrapper, Root, StyledProgressBar, StyledRewardBadge, StyledRewardBadgeSkeleton, Title, TitleRow } from './ProgressAchievementBadge.styles';
interface Props extends PropsWithClassName {
  title: string;
  stars: number;
  percent: number;
  points: number;
  levelMax: number | null;
  createdAt: string;
  achievementType: AchievementType;
  isHighlighted?: boolean;
  isSmall?: boolean;
}
const ProgressAchievementBadge = (props: Props): React.ReactElement => {
  const {
    title,
    stars,
    percent,
    points,
    levelMax,
    achievementType,
    createdAt,
    isHighlighted = false,
    isSmall = false
  } = props;
  const router = useRouter();
  const pathname = usePathname();
  const [isHighlightedState, setIsHighlightedState] = React.useState<boolean>(isHighlighted);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setIsHighlightedState(isHighlighted);
    if (isHighlighted && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [isHighlighted]);
  const handleHover = async () => {
    if (!isHighlightedState) {
      return;
    }
    router.replaceShallow(pathname ?? '');
    setIsHighlightedState(false);
  };
  const {
    t
  } = useTranslation('global');
  const renderLevels = (): React.ReactNode => {
    const starsMax = 5;
    return <LevelContainer data-sentry-element="LevelContainer" data-sentry-component="renderLevels" data-sentry-source-file="ProgressAchievementBadge.tsx">
        {[...Array(starsMax)].map((e, i) => <Level key={'star' + i} isFilled={i <= stars} $isSmall={isSmall} />)}
      </LevelContainer>;
  };
  const getSubtitle = () => {
    // todo: add "createdAt" as an argument to the translation. For this the DateTimeDistance component should be refactored to return just a string
    return `${t('progress-achievements.next_unlock', {
      nextUnlockAt: levelMax ? levelMax - points : 0
    })} - ${t('progress-achievements.unlocked_at')} `;
  };
  if (isSmall) {
    return <Root className={props.className} data-testid={props.dataTestId ?? 'progress-achievement-badge-root'} $isHighlighted={isHighlightedState} ref={ref} onMouseEnter={handleHover} $isSmall>
        <div>
          <StyledRewardBadge achievementType={achievementType} icon={getAchievementIcon(achievementType)} size="small" />
        </div>
        <Content $isSmall>
          <TitleRow>
            <Title size={TextSize.Small} textTransform={TextTransform.Uppercase} isTruncated>
              {title}
            </Title>
            {renderLevels()}
          </TitleRow>
          <Row noGutters>
            <StyledProgressBar progress={percent} />
          </Row>
        </Content>
      </Root>;
  }
  return <Root className={props.className} data-testid={props.dataTestId ?? 'progress-achievement-badge-root'} $isHighlighted={isHighlightedState} $isSmall={false} ref={ref} onMouseEnter={handleHover} data-sentry-element="Root" data-sentry-component="ProgressAchievementBadge" data-sentry-source-file="ProgressAchievementBadge.tsx">
      <div>
        <StyledRewardBadge achievementType={achievementType} icon={getAchievementIcon(achievementType)} size="default" data-sentry-element="StyledRewardBadge" data-sentry-source-file="ProgressAchievementBadge.tsx" />
      </div>
      <Content $isSmall={false} data-sentry-element="Content" data-sentry-source-file="ProgressAchievementBadge.tsx">
        <TitleRow data-sentry-element="TitleRow" data-sentry-source-file="ProgressAchievementBadge.tsx">
          <Title size={TextSize.Medium} textTransform={TextTransform.Uppercase} isTruncated data-sentry-element="Title" data-sentry-source-file="ProgressAchievementBadge.tsx">
            {title}
          </Title>
          <PointsWrapper data-sentry-element="PointsWrapper" data-sentry-source-file="ProgressAchievementBadge.tsx">
            <Text as="span" size={TextSize.Medium} data-sentry-element="Text" data-sentry-source-file="ProgressAchievementBadge.tsx">
              {`${points} `}
            </Text>
            {levelMax && <Text as="span" size={TextSize.Medium} color={TextColor.Tertiary}>
                / {levelMax}
              </Text>}
          </PointsWrapper>
          {renderLevels()}
        </TitleRow>
        <Row noGutters data-sentry-element="Row" data-sentry-source-file="ProgressAchievementBadge.tsx">
          <StyledProgressBar progress={percent} data-sentry-element="StyledProgressBar" data-sentry-source-file="ProgressAchievementBadge.tsx" />
        </Row>
        <Row noGutters data-sentry-element="Row" data-sentry-source-file="ProgressAchievementBadge.tsx">
          <Text size={TextSize.Small} color={TextColor.Tertiary} isTruncated data-sentry-element="Text" data-sentry-source-file="ProgressAchievementBadge.tsx">
            {getSubtitle()}
            <DateTimeDistance distanceDate={createdAt} addSuffix data-sentry-element="DateTimeDistance" data-sentry-source-file="ProgressAchievementBadge.tsx" />
          </Text>
        </Row>
      </Content>
    </Root>;
};
const ProgressAchievementBadgeSkeleton = (props: PropsWithClassName): React.ReactElement => {
  const renderLevels = (): React.ReactNode => {
    const starsMax = 5;
    return <LevelContainer data-sentry-element="LevelContainer" data-sentry-component="renderLevels" data-sentry-source-file="ProgressAchievementBadge.tsx">
        {[...Array(starsMax)].map((e, i) => <Level $isSmall={false} key={'star' + i} isFilled={false} />)}
      </LevelContainer>;
  };
  return <Root className={props.className} data-testid={props.dataTestId ?? 'progress-achievement-badge-root'} $isHighlighted={false} $isSmall={false} onMouseEnter={() => null} data-sentry-element="Root" data-sentry-component="ProgressAchievementBadgeSkeleton" data-sentry-source-file="ProgressAchievementBadge.tsx">
      <div>
        <StyledRewardBadgeSkeleton size="default" data-sentry-element="StyledRewardBadgeSkeleton" data-sentry-source-file="ProgressAchievementBadge.tsx" />
      </div>
      <Content $isSmall={false} data-sentry-element="Content" data-sentry-source-file="ProgressAchievementBadge.tsx">
        <TitleRow data-sentry-element="TitleRow" data-sentry-source-file="ProgressAchievementBadge.tsx">
          <Skeleton data-sentry-element="Skeleton" data-sentry-source-file="ProgressAchievementBadge.tsx">
            <Title size={TextSize.Medium} isTruncated data-sentry-element="Title" data-sentry-source-file="ProgressAchievementBadge.tsx">
              {getStringWithLength(10)}
            </Title>
          </Skeleton>
          <PointsWrapper data-sentry-element="PointsWrapper" data-sentry-source-file="ProgressAchievementBadge.tsx">
            <Skeleton data-sentry-element="Skeleton" data-sentry-source-file="ProgressAchievementBadge.tsx">
              <Text size={TextSize.Small} data-sentry-element="Text" data-sentry-source-file="ProgressAchievementBadge.tsx">{getStringWithLength(7)}</Text>
            </Skeleton>
          </PointsWrapper>
          {renderLevels()}
        </TitleRow>
        <Row noGutters data-sentry-element="Row" data-sentry-source-file="ProgressAchievementBadge.tsx">
          <StyledProgressBar progress={0} data-sentry-element="StyledProgressBar" data-sentry-source-file="ProgressAchievementBadge.tsx" />
        </Row>
        <Row noGutters data-sentry-element="Row" data-sentry-source-file="ProgressAchievementBadge.tsx">
          <Skeleton width="100%" data-sentry-element="Skeleton" data-sentry-source-file="ProgressAchievementBadge.tsx">
            <Title size={TextSize.Xs} textTransform={TextTransform.Uppercase} isTruncated data-sentry-element="Title" data-sentry-source-file="ProgressAchievementBadge.tsx">
              {getStringWithLength(1)}
            </Title>
          </Skeleton>
        </Row>
      </Content>
    </Root>;
};
ProgressAchievementBadge.Skeleton = ProgressAchievementBadgeSkeleton;
export default ProgressAchievementBadge;