import React from 'react';
import { PropsWithClassName } from '@Components/helper';
interface Props extends PropsWithClassName {}
const ChartReward2 = (props: Props): React.ReactElement => {
  return <div className={props.className} style={{
    display: 'flex'
  }} data-testid="chart-reward-2-root" data-sentry-component="ChartReward2" data-sentry-source-file="ChartReward2.tsx">
      <svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="ChartReward2.tsx">
        <path d="M1645.57 504.216C1817.96 563.433 1940.71 706.736 1940.71 706.736L1859.44 943.322L-601.761 97.8565L-449.895 -344.232C-449.895 -344.232 -367.967 -172.847 -234.404 -149.986C6.33579 -108.782 -70.9862 38.2251 129.298 107.027C276.411 157.563 486.268 -165.134 688.169 -94.9955C890.07 -24.8568 755.343 304.167 1073.06 408.409C1311.07 486.499 1512.36 458.454 1645.57 504.216Z" fill="url(#paint0_linear_574_70366)" fillOpacity="0.5" data-sentry-element="path" data-sentry-source-file="ChartReward2.tsx" />
        <defs data-sentry-element="defs" data-sentry-source-file="ChartReward2.tsx">
          <linearGradient id="paint0_linear_574_70366" x1="868.839" y1="-178.066" x2="661.065" y2="479.612" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="ChartReward2.tsx">
            <stop stopColor="#B6DFEA" data-sentry-element="stop" data-sentry-source-file="ChartReward2.tsx" />
            <stop offset="1" stopColor="#333333" data-sentry-element="stop" data-sentry-source-file="ChartReward2.tsx" />
          </linearGradient>
        </defs>
      </svg>
    </div>;
};
export default ChartReward2;