import { IconLock, IconSparkles, IconUsers } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AchievementModel from '@Api/models/AchievementModel';
import UserAchievementModel, { AchievementCategory, AchievementType } from '@Api/models/UserAchievementModel';
import DateTime from '@Components/atoms/DateTime/DateTime';
import { Icon } from '@Components/atoms/Icon/Icon';
import Text from '@Components/atoms/Text';
import { PropsWithClassName } from '@Components/helper';
import { DateTimeFormats } from '@Components/helper/date';
import { TextColor, TextSize, TextTransform } from '@Helpers/types/text';
import { Badge, BadgeIcon, Circle, Root, StyledChartRewardDetail1, StyledChartRewardDetail2, StyledChartRewardDetail3, StyledChartRewardGenerated, StyledHeadline, StyledIcon, StyledText, TitleContainer, UserPercentage, UserPercentageContainer } from './AchievementDetail.styles';
interface Props extends PropsWithClassName {
  achievement: UserAchievementModel | AchievementModel;
  icon: string;
  points?: number;
  isDisabled?: boolean;
}
const AchievementDetail = (props: Props): React.ReactElement => {
  const {
    achievement,
    icon,
    points,
    isDisabled = false
  } = props;
  const {
    t
  } = useTranslation('achievements');
  const levelUnits = (achievementType: string): string => {
    switch (achievementType) {
      case AchievementType.Progress_Competition_Competitor:
        return t('detail.stats.level_unit.participated_competitions');
      case AchievementType.Progress_Competition_Winner:
        return t('detail.stats.level_unit.competitions_won');
      case AchievementType.Progress_TextPractice_Practicer:
        return t('detail.stats.level_unit.participated_text_practices');
      case AchievementType.Progress_TypingTest_Grinder:
        return t('detail.stats.level_unit.tests_taken');
      case AchievementType.Progress_TypingTest_Flawless:
      case AchievementType.Progress_TypingTest_TypingSpeedAdvanced:
      case AchievementType.Progress_TypingTest_TypingSpeedNormal:
        return t('units.wpm.short', {
          ns: 'global'
        });
      default:
        return t('detail.stats.level_unit.wins');
    }
  };
  const getStatsNextLevel = (levelMax: number | null) => {
    return `${t('detail.stats.next_level')}: ${points}/${levelMax || '∞'} ${levelUnits(achievement.achievementType)}`;
  };
  return <Root className={props.className} data-testid={props.dataTestId ?? 'achievement-detail-root'} data-sentry-element="Root" data-sentry-component="AchievementDetail" data-sentry-source-file="AchievementDetail.tsx">
      <Badge $isDisabled={isDisabled} data-sentry-element="Badge" data-sentry-source-file="AchievementDetail.tsx">
        <StyledChartRewardDetail1 data-sentry-element="StyledChartRewardDetail1" data-sentry-source-file="AchievementDetail.tsx" />
        <StyledChartRewardDetail2 data-sentry-element="StyledChartRewardDetail2" data-sentry-source-file="AchievementDetail.tsx" />
        <StyledChartRewardDetail3 data-sentry-element="StyledChartRewardDetail3" data-sentry-source-file="AchievementDetail.tsx" />
        <StyledChartRewardGenerated seed={achievement.achievementType} width={282} height={75} data-sentry-element="StyledChartRewardGenerated" data-sentry-source-file="AchievementDetail.tsx" />
        <Circle data-sentry-element="Circle" data-sentry-source-file="AchievementDetail.tsx">
          <BadgeIcon $src={icon} data-sentry-element="BadgeIcon" data-sentry-source-file="AchievementDetail.tsx" />
        </Circle>
      </Badge>
      <TitleContainer data-sentry-element="TitleContainer" data-sentry-source-file="AchievementDetail.tsx">
        {achievement instanceof AchievementModel && <div>
            <Icon icon={IconLock} />
          </div>}
        {!achievement.isVisible && <div>
            <Icon icon={IconSparkles} />
          </div>}
        <StyledHeadline importance={4} transform={TextTransform.Uppercase} data-sentry-element="StyledHeadline" data-sentry-source-file="AchievementDetail.tsx">
          {achievement instanceof UserAchievementModel ? achievement.title : achievement.name}
        </StyledHeadline>
        <UserPercentageContainer $userPercentage={achievement.userPercentage} data-sentry-element="UserPercentageContainer" data-sentry-source-file="AchievementDetail.tsx">
          <StyledIcon $userPercentage={achievement.userPercentage} icon={IconUsers} size={15} strokeWidth={2.5} data-sentry-element="StyledIcon" data-sentry-source-file="AchievementDetail.tsx" />
          <UserPercentage $userPercentage={achievement.userPercentage} size={TextSize.Xs} bold data-sentry-element="UserPercentage" data-sentry-source-file="AchievementDetail.tsx">
            {achievement.userPercentage}%
          </UserPercentage>
        </UserPercentageContainer>
      </TitleContainer>
      <StyledText size={TextSize.Xs} color={TextColor.Tertiary} data-sentry-element="StyledText" data-sentry-source-file="AchievementDetail.tsx">
        {achievement.description}
      </StyledText>
      {achievement instanceof UserAchievementModel && <Text size={TextSize.Xs} color={TextColor.Secondary}>
          <>
            {t('detail.stats.unlocked')}:{' '}
            <DateTime className={`unlockedDate`} date={achievement.createdAt} dateFormat={DateTimeFormats.LocalizedDateLong} />
          </>
        </Text>}
      {achievement instanceof UserAchievementModel && achievement.category === AchievementCategory.Progress && <Text size={TextSize.Xs} color={TextColor.Secondary}>
          {getStatsNextLevel(achievement.levelMax)}
        </Text>}
    </Root>;
};
export default AchievementDetail;