import React from 'react';
import { useTheme } from 'styled-components';
import { PropsWithClassName } from '@Components/helper';
import { Root } from './ChartAchievementDetail3.styles';
interface Props extends PropsWithClassName {}
const ChartAchievementDetail3 = (props: Props): React.ReactElement => {
  const theme = useTheme();
  return <Root className={props.className} data-testid="chart-achievement-detail-3-root" data-sentry-element="Root" data-sentry-component="ChartAchievementDetail3" data-sentry-source-file="ChartAchievementDetail3.tsx">
      <svg width="282" height="151" viewBox="0 0 282 151" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="ChartAchievementDetail3.tsx">
        <path d="M1645.11 504.097C1817.49 563.314 1940.24 706.617 1940.24 706.617L1858.97 943.202L-602.229 97.7374L-450.364 -344.351C-450.364 -344.351 -368.436 -172.966 -234.873 -150.105C5.86704 -108.901 -71.4549 38.1059 128.829 106.907C275.943 157.444 485.799 -165.253 687.7 -95.1146C889.601 -24.9759 754.874 304.047 1072.59 408.29C1310.6 486.38 1511.89 458.335 1645.11 504.097Z" fill="url(#paint0_linear_574_74430)" fillOpacity="0.5" data-sentry-element="path" data-sentry-source-file="ChartAchievementDetail3.tsx" />
        <defs data-sentry-element="defs" data-sentry-source-file="ChartAchievementDetail3.tsx">
          <linearGradient id="paint0_linear_574_74430" x1="868.37" y1="-178.185" x2="660.596" y2="479.493" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="ChartAchievementDetail3.tsx">
            <stop stopColor={theme.palette.achievement.detailChart} data-sentry-element="stop" data-sentry-source-file="ChartAchievementDetail3.tsx" />
            <stop offset="1" stopColor="#333333" data-sentry-element="stop" data-sentry-source-file="ChartAchievementDetail3.tsx" />
          </linearGradient>
        </defs>
      </svg>
    </Root>;
};
export default ChartAchievementDetail3;