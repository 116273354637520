import React from 'react';
import { AchievementType } from '@Api/models/UserAchievementModel';
import IconBrawler from '@Components/atoms/Icons/Achievements/IconBrawler';
import IconFlawless from '@Components/atoms/Icons/Achievements/IconFlawless';
import IconGrinder from '@Components/atoms/Icons/Achievements/IconGrinder';
import IconPracticer from '@Components/atoms/Icons/Achievements/IconPracticer';
import IconRacer from '@Components/atoms/Icons/Achievements/IconRacer';
import IconRepeater from '@Components/atoms/Icons/Achievements/IconRepeater';
import IconCompetition from '@Components/atoms/Icons/IconCompetition/IconCompetition';
import IconTypingtest from '@Components/atoms/Icons/IconTypingtest/IconTypingtest';
import astronaut from '@Assets/img/rewards/astronaut.png';
import moon from '@Assets/img/rewards/moon.png';
import rocket from '@Assets/img/rewards/rocket.png';
import satellite from '@Assets/img/rewards/satellite.png';
import spaceCapsule from '@Assets/img/rewards/space-capsule.png';
import telescope from '@Assets/img/rewards/telescope.png';
import ufo from '@Assets/img/rewards/ufo.png';
export const getProgressAchievementIcon = (achievementType: AchievementType, isHeader: boolean = false): React.ReactNode => {
  switch (achievementType) {
    case AchievementType.Progress_TypingTest_TypingSpeedNormal:
      return <IconTypingtest isHeader={isHeader} />;
    case AchievementType.Progress_TypingTest_TypingSpeedAdvanced:
      return <IconTypingtest isHeader={isHeader} />;
    case AchievementType.Progress_TypingTest_Grinder:
      return <IconGrinder />;
    case AchievementType.Progress_TypingTest_Flawless:
      return <IconFlawless />;
    case AchievementType.Progress_Competition_Competitor:
      return <IconCompetition isHeader={isHeader} />;
    // return <IconCompetitor />;
    case AchievementType.Progress_Competition_Winner:
      //return <IconWinner />;
      return <IconCompetition isHeader={isHeader} />;
    case AchievementType.Progress_Competition_Repeater:
      return <IconRepeater />;
    case AchievementType.Progress_TextPractice_Practicer:
      return <IconPracticer />;
    case AchievementType.Progress_Multiplayer_Racer:
      return <IconRacer />;
    case AchievementType.Progress_Multiplayer_Brawler:
      return <IconBrawler />;
    default:
      return <></>;
  }
};

// todo: replace with correct icons
export const getAchievementIcon = (achievementType: AchievementType): string => {
  switch (achievementType) {
    case AchievementType.Reward_Theme_WhoTurnedTheLightsOff:
    case AchievementType.Progress_TypingTest_TypingSpeedNormal:
    case AchievementType.Reward_Social_Superfan:
      return moon.src;
    case AchievementType.Reward_CustomTypingTest_Fanboy:
    case AchievementType.Progress_TypingTest_TypingSpeedAdvanced:
    case AchievementType.Reward_GameMode_HappyNewYear:
      return astronaut.src;
    case AchievementType.Reward_GameMode_VarietyVoyager:
    case AchievementType.Progress_Competition_Competitor:
    case AchievementType.Reward_GameMode_Pacemaker:
      return rocket.src;
    case AchievementType.Reward_GameMode_Afk:
    case AchievementType.Progress_Competition_Repeater:
    case AchievementType.Reward_GameMode_NightShift:
      return ufo.src;
    case AchievementType.Reward_General_Asdf:
    case AchievementType.Progress_TypingTest_Flawless:
    case AchievementType.Reward_GameMode_MorningShift:
    case AchievementType.Reward_TextPractice_TheNumbers:
      return satellite.src;
    case AchievementType.Reward_General_StayAWhileAndListen:
    case AchievementType.Progress_TypingTest_Grinder:
    case AchievementType.Reward_GameMode_Skippy:
    case AchievementType.Reward_GameMode_ClimbingTheLadder:
      return moon.src;
    case AchievementType.Progress_TextPractice_Practicer:
    case AchievementType.Reward_Social_OneOfUs:
    case AchievementType.Reward_Theme_LetThereBeLight:
    case AchievementType.Reward_Social_ProfileDescription:
      return spaceCapsule.src;
    case AchievementType.Reward_Result_Scientist:
    case AchievementType.Reward_GameMode_OneArmBehindMyBack:
    case AchievementType.Progress_Competition_Winner:
    case AchievementType.Reward_TextPractice_QuickBrownFox:
      return telescope.src;
    default:
      return '';
  }
};