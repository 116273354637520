import React from 'react';
import { useTheme } from 'styled-components';
import { PropsWithClassName } from '@Components/helper';
import { Root } from './ChartAchievementDetail2.styles';
interface Props extends PropsWithClassName {}
const ChartAchievementDetail2 = (props: Props): React.ReactElement => {
  const theme = useTheme();
  return <Root className={props.className} data-testid="chart-achievement-detail-2-root" data-sentry-element="Root" data-sentry-component="ChartAchievementDetail2" data-sentry-source-file="ChartAchievementDetail2.tsx">
      <svg width="282" height="151" viewBox="0 0 282 151" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="ChartAchievementDetail2.tsx">
        <path d="M1621.33 495.108C1793.72 554.325 1916.47 697.629 1916.47 697.629L1835.19 934.214L-626.003 88.7491L-474.138 -353.34C-474.138 -353.34 -392.209 -181.954 -258.646 -159.094C-17.9064 -117.889 -95.2284 29.1177 105.056 97.9191C252.169 148.455 462.025 -174.242 663.927 -104.103C865.828 -33.9642 731.101 295.059 1048.82 399.302C1286.83 477.391 1488.12 449.347 1621.33 495.108Z" fill="url(#paint0_linear_574_74429)" fillOpacity="0.7" data-sentry-element="path" data-sentry-source-file="ChartAchievementDetail2.tsx" />
        <defs data-sentry-element="defs" data-sentry-source-file="ChartAchievementDetail2.tsx">
          <linearGradient id="paint0_linear_574_74429" x1="844.597" y1="-187.174" x2="636.823" y2="470.505" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="ChartAchievementDetail2.tsx">
            <stop stopColor={theme.palette.achievement.detailChart} data-sentry-element="stop" data-sentry-source-file="ChartAchievementDetail2.tsx" />
            <stop offset="1" stopColor="#333333" data-sentry-element="stop" data-sentry-source-file="ChartAchievementDetail2.tsx" />
          </linearGradient>
        </defs>
      </svg>
    </Root>;
};
export default ChartAchievementDetail2;