'use client';

import { IconShare } from '@tabler/icons-react';
import React from 'react';
import UserAchievementModel from '@Api/models/UserAchievementModel';
import Col from '@Components/atoms/Col';
import IconButton from '@Components/atoms/IconButton';
import Row from '@Components/atoms/Row';
import { PropsWithClassName } from '@Components/helper';
import { getDynamicSubRoute } from '@Components/helper/url';
import { useBreakpoint } from '@Components/helper/useBreakpoint';
import ProgressAchievement from '@Components/organisms/ProgressAchievement/ProgressAchievement';
import { Routes } from '@Components/Routes';
import useRouter from '@Helpers/i18n/useRouter';
import { ShareMode } from '@Helpers/types/share';
import { TextSize } from '@Helpers/types/text';
import { StyledText } from './ProgressAchievementNotification.styles';
interface Props extends PropsWithClassName {
  message: string;
  achievement: UserAchievementModel;
}
const ProgressAchievementNotification = (props: Props): React.ReactElement => {
  const {
    message,
    achievement
  } = props;
  const router = useRouter();
  const {
    isMobile
  } = useBreakpoint();
  return <div className={props.className} data-testid={props.dataTestId ?? 'progress-achievement-notification-root'} data-sentry-component="ProgressAchievementNotification" data-sentry-source-file="ProgressAchievementNotification.tsx">
      <Row noGutters xsBetween data-sentry-element="Row" data-sentry-source-file="ProgressAchievementNotification.tsx">
        <Col xs={10} data-sentry-element="Col" data-sentry-source-file="ProgressAchievementNotification.tsx">
          <ProgressAchievement userAchievementModel={achievement} hideTooltip isSmall={isMobile} data-sentry-element="ProgressAchievement" data-sentry-source-file="ProgressAchievementNotification.tsx" />
        </Col>
        <Col xs={1} data-sentry-element="Col" data-sentry-source-file="ProgressAchievementNotification.tsx">
          <Row xsEnd noGutters data-sentry-element="Row" data-sentry-source-file="ProgressAchievementNotification.tsx">
            <IconButton onClick={() => router.push(getDynamicSubRoute(Routes.Share, ShareMode.Achievement, achievement.id, 'image'))} data-sentry-element="IconButton" data-sentry-source-file="ProgressAchievementNotification.tsx">
              <IconShare data-sentry-element="IconShare" data-sentry-source-file="ProgressAchievementNotification.tsx" />
            </IconButton>
          </Row>
        </Col>
      </Row>
      <StyledText size={TextSize.Medium} mobileSize={TextSize.Small} data-sentry-element="StyledText" data-sentry-source-file="ProgressAchievementNotification.tsx">
        {message}
      </StyledText>
    </div>;
};
export default ProgressAchievementNotification;