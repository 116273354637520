import { usePathname } from 'next/navigation';
import React, { useEffect, useRef } from 'react';
import { AchievementType } from '@Api/models/UserAchievementModel';
import Skeleton from '@Components/atoms/Skeleton';
import { PropsWithClassName } from '@Components/helper';
import useRouter from '@Helpers/i18n/useRouter';
import { Background, Circle, Icon, Root, StyledChartReward1, StyledChartReward2, StyledChartReward3, StyledChartRewardGenerated } from './RewardBadge.styles';
interface Props extends PropsWithClassName {
  achievementType: AchievementType;
  icon: string;
  isDisabled?: boolean;
  size: 'small' | 'default';
  isHighlighted?: boolean;
}
const RewardBadge = (props: Props): React.ReactElement => {
  const {
    achievementType,
    icon,
    isDisabled = false,
    size,
    isHighlighted = false
  } = props;
  const router = useRouter();
  const pathname = usePathname();
  const [isHighlightedState, setIsHighlightedState] = React.useState<boolean>(isHighlighted);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setIsHighlightedState(isHighlighted);
    if (isHighlighted && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [isHighlighted]);
  const handleHover = async () => {
    if (!isHighlightedState) {
      return;
    }
    router.replaceShallow(pathname ?? '');
    setIsHighlightedState(false);
  };
  return <Root className={props.className} data-testid={props.dataTestId ?? 'reward-badge-root'} $isDisabled={isDisabled} $size={size} $isHighlighted={isHighlightedState} ref={ref} onMouseEnter={handleHover} data-sentry-element="Root" data-sentry-component="RewardBadge" data-sentry-source-file="RewardBadge.tsx">
      <Background $size={size} $isDisabled={isDisabled} data-sentry-element="Background" data-sentry-source-file="RewardBadge.tsx">
        <StyledChartReward1 $size={size} data-sentry-element="StyledChartReward1" data-sentry-source-file="RewardBadge.tsx" />
        <StyledChartReward2 $size={size} data-sentry-element="StyledChartReward2" data-sentry-source-file="RewardBadge.tsx" />
        <StyledChartReward3 $size={size} data-sentry-element="StyledChartReward3" data-sentry-source-file="RewardBadge.tsx" />
        <StyledChartRewardGenerated seed={achievementType} width={70} height={25} $size={size} data-sentry-element="StyledChartRewardGenerated" data-sentry-source-file="RewardBadge.tsx" />
        <Circle $isDisabled={isDisabled} data-sentry-element="Circle" data-sentry-source-file="RewardBadge.tsx">
          <Icon $src={icon} data-sentry-element="Icon" data-sentry-source-file="RewardBadge.tsx" />
        </Circle>
      </Background>
    </Root>;
};
const RewardBadgeSkeleton = (props: PropsWithClassName & {
  size: 'small' | 'default';
}): React.ReactElement => {
  const size = props.size === 'default' ? '4.25rem' : '2.125rem';
  return <Root className={props.className} data-testid={props.dataTestId ?? 'reward-badge-root'} $size={props.size} $isHighlighted={false} $isDisabled={false} data-sentry-element="Root" data-sentry-component="RewardBadgeSkeleton" data-sentry-source-file="RewardBadge.tsx">
      <Skeleton width={size} height={size} data-sentry-element="Skeleton" data-sentry-source-file="RewardBadge.tsx" />
    </Root>;
};
RewardBadge.Skeleton = RewardBadgeSkeleton;
export default RewardBadge;