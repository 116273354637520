import React from 'react';
import { toast } from 'react-toastify';
import { ApiError } from '@Api/ApiError';
import AchievementLevelUpModel from '@Api/models/NotificationMetaData/AchievementLevelUpModel';
import AchievementUnlockedNotificationModel from '@Api/models/NotificationMetaData/AchievementUnlockedNotificationModel';
import NotificationModel, { NotificationType } from '@Api/models/NotificationModel';
import ProgressAchievementNotification from '@Components/atoms/ProgressAchievementNotification/ProgressAchievementNotification';
import RewardNotification from '@Components/atoms/RewardNotification/RewardNotification';
export function successToast(message: string) {
  toast(message, {
    type: 'success',
    closeOnClick: true
  });
}
export function errorToast(error: string | ApiError | null) {
  let message: string | ApiError | null = error;
  if (error instanceof ApiError) {
    message = error.message;
    if (error.status === 500) {
      message = `An unexpected error occurred (Status [${error.status}] with code [${error.body.code}]`;
    }
    if (error.status === 403) {
      message = error.body.message;
    }
  }
  if (typeof message === 'string') {
    toast(message, {
      type: 'error',
      autoClose: false,
      closeOnClick: true
    });
  }
}
export const notificationToast = (message: string, notification: NotificationModel) => {
  switch (notification.notificationType) {
    case NotificationType.ProgressAchievementUnlocked_Toast:
    case NotificationType.ProgressAchievementLevelUp_Toast:
    case NotificationType.ProgressAchievementUnlocked_App:
    case NotificationType.ProgressAchievementLevelUp_App:
      if (!(notification.metaData instanceof AchievementLevelUpModel) && !(notification.metaData instanceof AchievementUnlockedNotificationModel)) {
        throw new Error('Unexpected Error: metaData should be an instance of AchievementLevelUpModel or AchievementUnlockedModel.');
      }
      toast(<ProgressAchievementNotification message={message} achievement={notification.metaData.userAchievement} />, {
        type: 'success',
        position: 'top-left',
        icon: false,
        autoClose: false,
        closeButton: false,
        closeOnClick: true,
        style: {
          width: '490px',
          maxWidth: '90vw'
        }
      });
      break;
    case NotificationType.RewardAchievementUnlocked_App:
    case NotificationType.RewardAchievementUnlocked_Toast:
      if (!(notification.metaData instanceof AchievementUnlockedNotificationModel)) {
        throw new Error('Unexpected Error: metaData should be an instance of AchievementUnlockedModel.');
      }
      toast(<RewardNotification message={message} achievement={notification.metaData.userAchievement} />, {
        type: 'success',
        position: 'top-left',
        icon: false,
        autoClose: false,
        closeButton: false,
        closeOnClick: true,
        style: {
          width: '400px',
          maxWidth: '90vw'
        }
      });
      break;
    case NotificationType.AntiCheatRequired_App:
    case NotificationType.CompetitionResult_App:
    case NotificationType.CompetitionWon_App:
    default:
      toast(message, {
        type: 'success',
        position: 'top-left',
        icon: false,
        autoClose: false,
        closeOnClick: true,
        style: {
          width: '400px'
        }
      });
      break;
  }
};