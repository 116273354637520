import React from 'react';
import { useTheme } from 'styled-components';
import { PropsWithClassName } from '@Components/helper';
import { Root } from './ChartAchievementDetail1.styles';
interface Props extends PropsWithClassName {}
const ChartAchievementDetail1 = (props: Props): React.ReactElement => {
  const theme = useTheme();
  return <Root className={props.className} data-testid="chart-achievement-detail-1-root" data-sentry-element="Root" data-sentry-component="ChartAchievementDetail1" data-sentry-source-file="ChartAchievementDetail1.tsx">
      <svg width="282" height="151" viewBox="0 0 282 151" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="ChartAchievementDetail1.tsx">
        <path d="M1671.66 -36.6911C1853.93 -36.6911 2016.58 58.9594 2016.58 58.9594V309.115H-585.787V-158.331C-585.787 -158.331 -452.622 -22.8594 -318.878 -44.6316C-77.8105 -83.8748 -103.178 80.2783 108.594 80.2785C264.146 80.2787 357.779 -293.092 571.515 -292.352C785.251 -291.613 764.726 63.333 1099.08 58.6988C1349.55 55.2272 1530.8 -36.6911 1671.66 -36.6911Z" fill="url(#paint0_linear_574_74433)" fillOpacity="0.2" data-sentry-element="path" data-sentry-source-file="ChartAchievementDetail1.tsx" />
        <defs data-sentry-element="defs" data-sentry-source-file="ChartAchievementDetail1.tsx">
          <linearGradient id="paint0_linear_574_74433" x1="715.396" y1="-429.614" x2="732.562" y2="259.891" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="ChartAchievementDetail1.tsx">
            <stop stopColor={theme.palette.achievement.detailChart} data-sentry-element="stop" data-sentry-source-file="ChartAchievementDetail1.tsx" />
            <stop offset="1" stopColor="#333333" data-sentry-element="stop" data-sentry-source-file="ChartAchievementDetail1.tsx" />
          </linearGradient>
        </defs>
      </svg>
    </Root>;
};
export default ChartAchievementDetail1;